/* Component: Commission Calculator */

/* Import CSS for pagination styled like dots */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.commission-calculator_foraSwiper__7czuh swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  position: static;
  display: flex;
  justify-content: center;
}

.commission-calculator_foraSwiper__7czuh swiper-container::part(bullet-active) {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 115, 99, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .commission-calculator_foraSwiper__7czuh swiper-container::part(bullet-active) {
    display: none;
  }
}

.commission-calculator_foraSwiper__7czuh swiper-container::part(bullet) {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 177, 164, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .commission-calculator_foraSwiper__7czuh swiper-container::part(bullet) {
    display: none;
  }
}

.commission-calculator_foraSwiper__7czuh swiper-slide {
  height: auto;
  padding-right: 1rem;
}

.commission-calculator_foraSwiper__7czuh swiper-container::part(pagination) {
  margin-top: 16px;
}

/* 
 * The following styles allow there to be left-spacing on the first slide without affecting the width of the first
 * slide or pushing part of the last slide out of view.
 */

.commission-calculator_foraSwiper__7czuh swiper-container {
  padding-left: 1rem;
}

@media (min-width: 640px) {

  .commission-calculator_foraSwiper__7czuh swiper-container {
    padding-left: 0px;
  }
}

.commission-calculator_foraSwiper__7czuh swiper-container::part(container) {
  margin-left: -16.1px;
  padding-left: 1rem;
}

@media (min-width: 640px) {

  .commission-calculator_foraSwiper__7czuh swiper-container::part(container) {
    margin-left: 0px;
  }

  .commission-calculator_foraSwiper__7czuh swiper-container::part(container) {
    padding-left: 0px;
  }
}

.commission-calculator_foraSwiper__7czuh swiper-container::part(container) { /* .1 resolves subpixel artifact  */
}

