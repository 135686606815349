/* Component: Commission Calculator */

/* Import CSS for pagination styled like dots */
@import '../common/dots-pagination.module.css';

.foraSwiper swiper-slide {
  @apply h-auto pr-4;
}

.foraSwiper swiper-container::part(pagination) {
  margin-top: 16px;
}

/* 
 * The following styles allow there to be left-spacing on the first slide without affecting the width of the first
 * slide or pushing part of the last slide out of view.
 */
.foraSwiper swiper-container {
  @apply pl-4 sm:pl-0;
}
.foraSwiper swiper-container::part(container) {
  @apply pl-4 ml-[-16.1px] sm:pl-0 sm:ml-0; /* .1 resolves subpixel artifact  */
}
